import { Splide } from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

export function slide() {
  if (document.querySelector(".splide01")) {
    new Splide(".splide01", {
      type: "loop",
      perPage: 3,
      perMove: 1,
      autoplay: true,
      interval: 3000,
      pauseOnHover: false,
      gap: "35px",
      focus: 0,
      breakpoints: {
        768: {
          perPage: 1,
          gap: "20px",
        },
      },
    }).mount();
  }
}
