import { accordion } from "./_accordion";
import { animation } from "./_animation";
import { common } from "./_common";
import { drawer } from "./_drawer";
import { slide } from "./_slide";
// import { test } from "./_test";

accordion();
animation();
common();
drawer();
slide();
// test();
